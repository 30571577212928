.App {
  text-align: center;
}
.width-auto {
  width: auto !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.widthTranslate {
  -webkit-transition-property: width;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
  transition-property: width;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-home {
  position: absolute;
  left: 0;
}
.MuiTab-root {
    max-width: 270px !important;
}
body{ min-height:100vh; margin:0; position:relative; }
body {
    position: relative;
}
body::after {
    content: '';
    display: block;
    height: 50px; /* Set same as footer's height */
}
.link:hover {
    text-decoration: underline;
}
.sources {
    word-break: break-word;
}
.sources a {
    color: black;
}
footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}
.footer p {
    font-size: 0.8rem;
}
.footer a {
    color: #eb5c5c;
}
.hidden {
}
.scrollTop {
    position: fixed;
    width: 50px;
    bottom: 20px;
    right: 20px;
    align-items: center;
    height: 50px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}
@media (min-width: 1200px) {
    .scrollTop {
        width: 50px;
        bottom: 50px;
    }
}
.MuiPaper-root, .MuiTabs-root, .MuiTab-root {
    border-radius: 20px !important;
}
.MuiTab-textColorPrimary.Mui-selected {
    border: 3px solid #eb5c5c !important;
}
.MuiTab-root:focus {
    outline: none !important;
}
.PrivateTabIndicator-root-5, .MuiTabs-indicator {
    height: 0px !important;
}
.scrollTop:hover{
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
.scroll-indicator {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
    z-index: 10;
}
@media (min-width: 1200px) {
    .scroll-indicator {
        width: 70px;
        height: 70px;
        top: 40px;
        left: 20px;
    }
}
.scroll-indicator svg path {
    stroke: #eb5c5c;
}
.tiny-text {
    font-size: 0.8rem;
}
@media (min-width: 1200px) {
    .tiny-text {
        font-size: 1rem;
    }
}
.box-loader {
  background-color: #b3bbfa;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
[class*="loader-"] {
  display: inline-block;
  width: 12em;
  height: 12em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
}

.loader-07 {
  border: 0 solid transparent;
  margin: auto;
  border-radius: 50%;
  position: relative;
}
.loader-07:before, .loader-07:after {
  content: '';
  border: .9em solid rgba(235, 92, 92,0.5);
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader-07 1.3s linear infinite;
  animation: loader-07 1.3s linear infinite;
  opacity: 0;
}
.loader-07:before {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loader-07:after {
  -webkit-animation-delay: .7s;
  animation-delay: .7s;
}

@-webkit-keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.min-chart-height {
    min-height: 350px;
}
.min-chart-wrapper-height {
    min-height: 450px;
}
@media (min-width: 992px) {
    .min-chart-height {
        min-height: 666px;
    }
    .min-chart-wrapper-height {
        min-height: 731px;
    }
}
.white-wrapper {
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0 8px 12px rgba(0, 0, 0, 0.24);
}
@media (max-width: 600px) {
    .white-wrapper {
        padding: 20px;
    }
}
.MuiAutocomplete-root[role=combobox] .MuiInputBase-root {
    padding-right: 5px !important;
}
@media (min-width: 1200px) {
  .white-wrapper {
    padding: 40px;
    border-radius: 50px;
  }
}
.mt-md-5, .my-md-5 {
    margin-top: 1.2rem !important;
}
.header {
    z-index: 100;
}
.my-md-5 {
    margin-bottom: 1.2rem !important;
}
.color-primary, strong {
  color: #eb5c5c;
}
* {
  font-family: 'Poppins', sans-serif !important;
}
body {
  background-color: #b3bbfa;
  /*background: rgba(232,229,255,1);*/
  /*background: -moz-linear-gradient(-45deg, rgba(232,229,255,1) 0%, rgba(232,229,255,1) 8%, rgba(221,230,255,1) 17%, rgba(221,230,255,1) 32%, rgba(221,230,255,1) 39%, rgba(221,230,255,1) 56%, rgba(221,230,255,1) 62%, rgba(234,247,255,1) 98%, rgba(234,247,255,1) 100%);*/
  /*background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(232,229,255,1)), color-stop(8%, rgba(232,229,255,1)), color-stop(17%, rgba(221,230,255,1)), color-stop(32%, rgba(221,230,255,1)), color-stop(39%, rgba(221,230,255,1)), color-stop(56%, rgba(221,230,255,1)), color-stop(62%, rgba(221,230,255,1)), color-stop(98%, rgba(234,247,255,1)), color-stop(100%, rgba(234,247,255,1)));*/
  /*background: -webkit-linear-gradient(-45deg, rgba(232,229,255,1) 0%, rgba(232,229,255,1) 8%, rgba(221,230,255,1) 17%, rgba(221,230,255,1) 32%, rgba(221,230,255,1) 39%, rgba(221,230,255,1) 56%, rgba(221,230,255,1) 62%, rgba(234,247,255,1) 98%, rgba(234,247,255,1) 100%);*/
  /*background: -o-linear-gradient(-45deg, rgba(232,229,255,1) 0%, rgba(232,229,255,1) 8%, rgba(221,230,255,1) 17%, rgba(221,230,255,1) 32%, rgba(221,230,255,1) 39%, rgba(221,230,255,1) 56%, rgba(221,230,255,1) 62%, rgba(234,247,255,1) 98%, rgba(234,247,255,1) 100%);*/
  /*background: -ms-linear-gradient(-45deg, rgba(232,229,255,1) 0%, rgba(232,229,255,1) 8%, rgba(221,230,255,1) 17%, rgba(221,230,255,1) 32%, rgba(221,230,255,1) 39%, rgba(221,230,255,1) 56%, rgba(221,230,255,1) 62%, rgba(234,247,255,1) 98%, rgba(234,247,255,1) 100%);*/
  /*background: linear-gradient(135deg, rgba(232,229,255,1) 0%, rgba(232,229,255,1) 8%, rgba(221,230,255,1) 17%, rgba(221,230,255,1) 32%, rgba(221,230,255,1) 39%, rgba(221,230,255,1) 56%, rgba(221,230,255,1) 62%, rgba(234,247,255,1) 98%, rgba(234,247,255,1) 100%);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e5ff', endColorstr='#eaf7ff', GradientType=1 );*/
}
.clickable:hover {
  cursor: pointer;
}
.disabled:hover {
  cursor: not-allowed;
}
.app-title {
  font-weight: 800;
}
.black {
  color: black !important;
}
.sliders * {
  font-size: 1rem !important;
}
.energy-pictos {
  margin-top: -20px;
  opacity: 0.1;
}
html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
    font-size: 1rem !important;
}
.MuiTabs-flexContainer {
    flex-wrap: wrap;
    justify-content: center;
}
@media (max-width: 374px) {
    .menu strong {
        font-size: 1.3rem;
    }
    .header .col-auto {
    }
}
canvas {
    max-height: 400px !important;
    min-height: 300px !important;
}
@media (min-width: 760px) {
    .versus-computings strong {
        font-size: 0.9rem;
    }
    canvas {
        max-height: 500px !important;
    }
}
@media (min-width: 1200px) {
    canvas {
        max-height: 530px !important;
        min-height: 500px !important;
    }
}
.doughnut-wrapper canvas, .bubble-wrapper canvas {
    max-height: unset !important;
    min-height: auto !important;
}
@media (min-width: 1200px) {
  body {
    font-size: 1.3rem !important;
  }
}
h1 {
  font-size: calc(1rem + 8vw) !important;
}
@media (min-width: 1200px) {
  h1 {
    font-size: 8rem !important;
  }
}
h4 {
  font-size: calc(1rem + 1.3vw) !important;
  font-weight: 800;
}
.sources {
    text-decoration: underline;
}
.mobile-animations {
    display: none;
}
@media (min-width: 1200px) {
  h4 {
    font-size: 2.3rem !important;
  }
}
.header {
  font-size: 1.2rem;
}
.header .clickable {
    min-height: 30px;
    display: inline-block;
}
.chart-legend {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}
.share-button {
    background-color: white;
    border: 2px solid black;
}
.MuiTabs-root {
    min-height: 42px !important;
}
@media (max-width: 600px) {
    .MuiTab-root {
        min-height: 38px !important;
    }
    .energy-pictos {
        opacity: 0.06;
    }
    .desktop-animations {
        display: none;
    }
    .mobile-animations {
        display: block;
    }
    .animations svg {
        max-height: 80px;
    }
    .versus-computings strong {
        display: block;
        font-size: 1rem !important;
    }
}
@media (max-height: 600px) {
    .mobile-animations {
        transform: translateY(-20px);
    }
}
@media (max-height: 570px) {
    .mobile-animations {
        transform: translateY(-35px);
    }
}
@media (max-height: 750px) {
    @media (min-width: 768px) {
        .mt-md-5, .my-md-5 {
            margin-top: 1.5rem !important;
        }
    }
    @media (pointer: coarse) {
        .MuiSlider-root {
            padding: 0 !important;
        }
    }
    @media (min-width: 800px) {
        .MuiTabs-root, .MuiTab-root {
            max-height: 40px !important;
            min-height: auto !important;
        }
    }
    .min-chart-wrapper-height {
        min-height: 500px;
    }
    html {
        font-size: 12px;
    }
    .min-chart-height {
        min-height: 700px;
    }
    canvas {
        max-height: 450px !important;
        min-height: 400px !important;
    }
    @media (max-width: 500px) {
        canvas {
            max-height: 350px !important;
            min-height: 240px !important;
        }
    }
}